<template>
	<div class="main1">
		<div v-for="(item,index) in memberList" :key="index">
			<!-- <invite-team :type="type" :codeObj="item"></invite-team> -->
			<invite-team :type="type" :codeObj="item"></invite-team>
		</div>
		
	</div>
</template>

<script>
	import InviteTeam from '@/components/content/InviteTeam.vue'
	import { inviteCode } from "@/api/Mine"
	export default {
		props: {},
		data(){
			return {
				type: '0',//0填写邀请码 1邀请团队
				memberList:[
					{
						code: '109384'
					},
					// {
					// 	code: '109384'
					// },
					// {
					// 	code: '109384'
					// }
				],
				invite_code: ''
			}
		},
		components:{
			InviteTeam
		},
		methods: {
			inviteCode(){
				inviteCode({invite_code:this.invite_code}).then((res) => {
				      console.log(res,'生成邀请码成功');
					  this.codeInfo.code = res.data
					  // this.randCreatorList = res.data
					  // this.list = res.data.list
					  // this.numMusician = res.data.count
					  // console.log(res.data.count,'res.data.list.count')
				    })
				    .catch((err) => {
				      console.log(err,'生成邀请码失败');
				    });
			}
			// toCreate(){
			// 	this.$router.push({
			// 		path: '/teamManagement/createTeamForm'
			// 	})
			// }
		}
	}
</script>

<style lang="less" scoped>
	.main1 {
		width: 100%;
	}
</style>